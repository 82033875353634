/* ============================================================================
   Webpack `publicPath` runtime setup
   ========================================================================= */

/**
 * Webpack `publicPath` defines a path for the resources loaded on-demand or
 * external resources like images, files, etc. By default, it matches the root of
 * the host where the page is served for.
 *
 * However, static assets for the advisor app are served from `qatrunk.pcapcloud.com`
 * where the pages are served from `qatrunkadvisor.pcapcloud.com`.
 *
 * This file sets the public path for Webpack on runtime from a global `staticUrl` variable.
 * It should be loaded when `staticUrl` doesn't match `baseUrl`. I.e. advisor app.
 */
if (process.env.NODE_ENV === "production") {
  __webpack_public_path__ = `${window.staticUrl ?? ""}/static/`; // eslint-disable-line camelcase, no-undef
}
